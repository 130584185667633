import React from 'react';
import { useState, useMemo } from 'react';
import BreedingParent from './BreedingParent.js';
import { AgGridReact } from 'ag-grid-react';
import { getRandomFromArray, getRandomIntInclusive } from '../Utils.js';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

export default function BreedCompanionsMenu(props) {

    const commonRarity = "Common";
    const uncommonRarity = "Uncommon";
    const rareRarity = "Rare";
    const veryRareRarity = "VR";

    const [breedingSettings, setBreedingSettings] = useState({
        numberOfParents: 2,
        numberOfChildren: 1,
        numGuaranteedVeryRareTraits: 0,
        numGuaranteedMutations: 0,
        numGuaranteedColorMutations: 0
    });

    const onNumParentsChanged = (event) => {

        let currNumParents = breedingSettings.numberOfParents;
        let newNumParents = event.target.value;

        if (newNumParents > currNumParents) {
            // Add new list of traits to parentTraits
            parentTraits.push([]);
        }
        else {
            // Remove list of traits from parentTraits
            parentTraits.pop();
        }

        setBreedingSettings(values => ({...values, numberOfParents: newNumParents}));
    }

    const onNumChildrenChanged = (event) => {
        setBreedingSettings(values => ({...values, numberOfChildren: event.target.value}));
    }

    const onNumGuaranteedVeryRareTraitsChanged = (event) => {
        setBreedingSettings(values => ({...values, numGuaranteedVeryRareTraits: event.target.value}));
    }

    const onNumGuaranteedMutationsChanged = (event) => {
        setBreedingSettings(values => ({...values, numGuaranteedMutations: event.target.value}));
    }

    const onNumGuaranteedColorMutationsChanged = (event) => {
        setBreedingSettings(values => ({...values, numGuaranteedColorMutations: event.target.value}));
    }

    const [parentSpecies, setParentSpecies] = useState(["Dog", "Dog"]);

    const [parentTraits, setParentTraits] = useState([[],[]]);

    const [offspring, setOffspring] = useState([]);

    const [offspringTableColumns] = useState([
        {field: 'number', flex: 1, suppressMovable: true},
        {field: 'species', flex: 1, suppressMovable: true},
        {field: 'mutation', flex: 2, suppressMovable: true},
        {field: 'traits', flex: 10, suppressMovable: true}
    ]);

    const defaultColDef = useMemo(() => { 
        return {
            sortable: true,
            filter: false,
            editable: true,
            resizable: true,
            cellStyle: {textAlign: 'left'}
        };
    }, []);

    const traitsAreSame = (trait1, trait2) => trait1.id === trait2.id;

    const setTraitsOfParent = (parentNum, traits) => {
        let currentTraits = parentTraits;
        currentTraits[parentNum] = traits;

        setParentTraits(currentTraits);
    }

    const setSpeciesOfParent = (parentNum, species) => {
        let currentSpecies = parentSpecies;
        currentSpecies[parentNum] = species;

        setParentSpecies(currentSpecies);
    }

    const breedCompanions = () => {

        console.log("Parent 1 traits: " + JSON.stringify(parentTraits[0]));

        console.log("Parent 2 traits: " + JSON.stringify(parentTraits[1]));

        let allCategories = [];
        props.allCompanionTraits.forEach(trait => {
            if (trait.id !== undefined && trait.id.length > 0) {

                let category = trait.category === "" ? undefined : trait.category;

                if (!allCategories.includes(category)) {
                    allCategories.push(category);
                }
            }
        });

        // If all parents have a trait of the same category, 
        // then all offspring are guaranteed to have one of those traits
        let guaranteedCategories = [];
        let categorizedParentTraits = {};
        let uncategorizedTraitsGuaranteed = [];
        let uncategorizedTraitsNotGuaranteed = [];

        allCategories.forEach(category => {

            if (category === undefined) {
                // Handle uncategorized traits
                let uncategorizedMasterTraits = props.allCompanionTraits.filter(
                    trait => trait.category === undefined || trait.category === "");

                uncategorizedMasterTraits.forEach(masterTrait => {

                    let foundParentWithTrait = false;
                    let foundParentWithoutTrait = false;

                    for (let i = 0; i < breedingSettings.numberOfParents; i++) {
                        let parentHasTrait = parentTraits[i].some(parentTrait => traitsAreSame(parentTrait, masterTrait));

                        if (parentHasTrait) {
                            foundParentWithTrait = true;
                        }
                        else {
                            foundParentWithoutTrait = true;
                        }
                    }

                    if (foundParentWithTrait) {
                        if (foundParentWithoutTrait) {
                            uncategorizedTraitsNotGuaranteed.push(masterTrait);
                        }
                        else {
                            uncategorizedTraitsGuaranteed.push(masterTrait);
                        }
                    }
                });

                // Print details to console
                if (uncategorizedTraitsGuaranteed.length > 0) {
                    console.log("[Uncategorized traits]: Children are guaranteed to inherit " + uncategorizedTraitsGuaranteed.map(trait => trait.name));
                }
                else {
                    console.log("[Uncategorized Traits]: No uncategorized traits are guaranteed.");
                }

                if (uncategorizedTraitsNotGuaranteed.length > 0) {
                    console.log("[Uncategorized Traits]: Children have a chance to inherit " + uncategorizedTraitsNotGuaranteed.map(trait => trait.name));
                }
                else {
                    console.log("[Uncategorized Traits]: No chance to randomly inherit uncategorized traits.");
                } 
                
            }
            else {
                // Handle categorized traits.
                let parentTraitsWithCategory = [];

                // Check which trait each parent has from the category, if any.
                for (let i = 0; i < breedingSettings.numberOfParents; i++) {

                    let parentTraitsFiltered = parentTraits[i].filter(trait => trait.category === category);
                    
                    // A parent shouldn't have multiple traits from the same category, but if they do then just take the first one.
                    if (parentTraitsFiltered.length > 0) {
                        parentTraitsWithCategory.push(parentTraitsFiltered[0]);
                    }    
                }
    
                // If all parents have the category, then the child is guaranteed to have the category.
                if(parentTraitsWithCategory.length === breedingSettings.numberOfParents) {

                    console.log("[" + category + "]: Children are guaranteed one of these traits: " 
                    + parentTraitsWithCategory.map(trait => trait.name));

                    guaranteedCategories.push(category);
                    categorizedParentTraits[category] = parentTraitsWithCategory;
                }
                // If only some parents have the category, then the child has a chance to have the category.
                else if(parentTraitsWithCategory.length > 0) {

                    console.log("[" + category + "]: Children have a chance to inherit one of these traits: "
                    + parentTraitsWithCategory.map(trait => trait.name));

                    categorizedParentTraits[category] = parentTraitsWithCategory;
                }
                // If no parents have the category, then the child can only get the category via mutation.
                else {
                    console.log("[" + category + "]: None of the parents have " + category + ", children have no chance to inherit.");
                }
            }
        });

        // Populate pool of possible species.
        let speciesPool = [];
        for (let i = 0; i < breedingSettings.numberOfParents; i++) {
            speciesPool.push(parentSpecies[i]);
        }

        console.log("[Species]: Will select from pool: " + JSON.stringify(speciesPool));

        // Possible mutations are any *listed* trait not owned by either parent
        let possibleMutations = [];
        props.listedCompanionTraits.forEach(listedTrait => {

            let foundParentWithTrait = false;

            for (let i = 0; i < breedingSettings.numberOfParents; i++) {
                let parentHasTrait = parentTraits[i].some(parentTrait => traitsAreSame(parentTrait, listedTrait));

                if (parentHasTrait) {
                    foundParentWithTrait = true;
                }
            }

            if (!foundParentWithTrait) {
                possibleMutations.push(listedTrait);
            }
        });

        console.log("[Mutation]: Possible mutation traits: " + possibleMutations.map(trait => trait.name));

        const offspring = [];

        let numMutations = breedingSettings.numGuaranteedMutations;

        // Even if there are guaranteed mutations, it's still possible to roll an extra one.
        if (numMutations < breedingSettings.numberOfChildren && (Math.random() < props.odds.chanceOfMutation)) {
            numMutations++;

            console.log("[Mutation]: " + breedingSettings.numGuaranteedMutations + " guaranteed mutations plus rolled an extra for a total of " + numMutations);
        }
        else {
            console.log("[Mutation]: " + breedingSettings.numGuaranteedMutations + " guaranteed mutations, did not roll any extra.");
        }

        let offspringIndices = [...Array(parseInt(breedingSettings.numberOfChildren)).keys()];
        let offspringWithMutations = [];

        for (let j = 0; j < numMutations; j++) {

            const randomIndex = getRandomIntInclusive(0, offspringIndices.length - 1);

            offspringWithMutations.push(offspringIndices.splice(randomIndex, 1));
        }

        console.log("[Mutation]: Indices of children with mutations: " + offspringWithMutations);

        // Color mutations only happen if they are guaranteed, they won't happen randomly.
        offspringIndices = [...Array(parseInt(breedingSettings.numberOfChildren)).keys()];
        let offspringWithColorMutations = [];

        for (let j = 0; j < breedingSettings.numGuaranteedColorMutations; j++) {

            const randomIndex = getRandomIntInclusive(0, offspringIndices.length - 1);

            offspringWithColorMutations.push(offspringIndices.splice(randomIndex, 1));
        }

        console.log("[Color Mutation]: Indices of children with color mutations: " + offspringWithColorMutations);

        // Very Rare traits can be guaranteed for some amount of children. This is in addition to Very Rare traits that pass on naturally.
        offspringIndices = [...Array(parseInt(breedingSettings.numberOfChildren)).keys()];
        let offspringWithGuaranteedVeryRareTraits = [];

        for (let j = 0; j < breedingSettings.numGuaranteedVeryRareTraits; j++) {

            const randomIndex = getRandomIntInclusive(0, offspringIndices.length - 1);

            offspringWithGuaranteedVeryRareTraits.push(offspringIndices.splice(randomIndex, 1));
        }

        console.log("[Bonus Very Rare Trait]: Indices of children guaranteed to get a bonus Very Rare trait: " + offspringWithGuaranteedVeryRareTraits);

        let veryRareParentTraits = [];
        // Get the set of all Very Rare traits from all parents.
        for (let i = 0; i < breedingSettings.numberOfParents; i++) {
            veryRareParentTraits.push(...parentTraits[i].filter(trait => trait.rarity === veryRareRarity));
        }

        console.log("[Bonus Very Rare Trait]: List of all Very Rare traits that parents have: " + veryRareParentTraits.map(t => JSON.stringify(t)));

        // For some reason if I name this variable "i" it causes errors, but only in prod?
        for (let index = 0; index < breedingSettings.numberOfChildren; index++) {

            let offspringLogTag = "[Child " + (index + 1) + "]";

            // Assign species
            let species = getRandomFromArray(speciesPool);

            // Make copy of guaranteed traits list
            let offspringTraits = uncategorizedTraitsGuaranteed.slice();

            // Assign random uncategorized traits
            uncategorizedTraitsNotGuaranteed.forEach(possibleTrait => {

                let uncategorizedLogTag = "[Uncategorized]";

                let oddsForTrait = getOddsForUncategorizedRarity(possibleTrait.rarity);

                console.log(offspringLogTag + uncategorizedLogTag + ": " + possibleTrait.name + " is " + possibleTrait.rarity + ", so child has a " + oddsForTrait + " chance to inherit it.");

                let roll = Math.random();

                if(roll < oddsForTrait) {
                    console.log(offspringLogTag + uncategorizedLogTag + ": Rolled " + roll + ", child does get " + possibleTrait.name + ".");
                    offspringTraits.push(possibleTrait);
                }
                else {
                    console.log(offspringLogTag + uncategorizedLogTag + ": Rolled " + roll + ", child does NOT get " + possibleTrait.name + ".");
                }
            });

            // Go through each category and assign traits accordingly
            allCategories.forEach(category => {
                if(category !== undefined) {

                    let categoryLogTag = "[" + category + "]";

                    let poolOfPossibleTraitsWithCategory = [];

                    if (categorizedParentTraits[category] !== undefined) {

                        let numberOfParentsWithCategory = categorizedParentTraits[category].length;

                        // Add traits to pool based on rarity
                        categorizedParentTraits[category].forEach(trait => {

                            let numSlotsInPool = getNumSlotsForRarity(trait.rarity);

                            for (let j = 0; j < numSlotsInPool; j++) {
                                poolOfPossibleTraitsWithCategory.push(trait);
                            }
                        });
                    
                        // The pool of possible traits could be empty if both parents had a Very Rare trait.
                        if (poolOfPossibleTraitsWithCategory.length > 0) {
                            if(guaranteedCategories.includes(category)) {
                                // Guarantee the child gets a trait of the category
                                offspringTraits.push(getRandomFromArray(poolOfPossibleTraitsWithCategory));
                            }
                            else {
                                if (numberOfParentsWithCategory === 1) {
                                    // Trait is passed on based on rarity
                                    // Chance is based on odds, not slots
                                    let trait = poolOfPossibleTraitsWithCategory[0];
                                    let chanceToPassOn = getOddsForUncategorizedRarity(trait.rarity);

                                    console.log(offspringLogTag + categoryLogTag + ": " + numberOfParentsWithCategory + "/" + breedingSettings.numberOfParents + " parents have " + category + ". Trait " + trait.name + " is " + trait.rarity + ", so child has a " + chanceToPassOn + " chance to inherit it.");

                                    let roll = Math.random();

                                    if (roll < chanceToPassOn) {
                                        console.log(offspringLogTag + categoryLogTag + ": Rolled " + roll + ", child does get " + trait.name);
                                        offspringTraits.push(trait);
                                    }
                                    else {
                                        console.log(offspringLogTag + categoryLogTag + ": Rolled " + roll + ", child does NOT get " + trait.name);
                                    }
                                }
                                else {
                                    // Child has a chance to get a trait of the category depending on what percentage of parents have it.
                                    // Chance is based on slots, not odds.
                                    let chanceToGetCategory = numberOfParentsWithCategory / breedingSettings.numberOfParents;

                                    console.log(offspringLogTag + categoryLogTag + ": " + numberOfParentsWithCategory + " out of " + breedingSettings.numberOfParents + " parents have " + category + ", which gives the child odds of " + chanceToGetCategory + " to inherit it.");

                                    let roll = Math.random();

                                    if (roll < chanceToGetCategory) {

                                        console.log(offspringLogTag + categoryLogTag + ": Rolled " + roll + ", child does get " + category);

                                        offspringTraits.push(getRandomFromArray(poolOfPossibleTraitsWithCategory));
                                    }
                                    else {
                                        console.log(offspringLogTag + categoryLogTag + ": Rolled " + roll + ", child does not get " + category);
                                    }
                                }
                            }
                        }
                    }
                }
            });

            // Assign bonus Very Rare trait, if applicable.
            if (offspringWithGuaranteedVeryRareTraits.some(num => parseInt(num) === index)) {

                // Shouldn't select a bonus trait if the child already has it or if they already have a very rare trait of the same category.
                let possibleBonusVeryRareTraits = veryRareParentTraits.filter(possibleBonusTrait =>
                    !offspringTraits.some(offspringTrait => traitsAreSame(possibleBonusTrait, offspringTrait)) &&
                    !offspringTraits.some(offspringTrait => offspringTrait.category !== undefined && offspringTrait.rarity === veryRareRarity && offspringTrait.category === possibleBonusTrait.category));

                console.log(offspringLogTag + "[Bonus Very Rare Trait]: Possible bonus Very Rare traits for this child: " + possibleBonusVeryRareTraits.map(t => t.name));

                if (possibleBonusVeryRareTraits.length > 0) {
                    var selectedBonusTrait = getRandomFromArray(possibleBonusVeryRareTraits);
                    offspringTraits.push(selectedBonusTrait);

                    console.log(offspringLogTag + "[Bonus Very Rare Trait]: Got this bonus Very Rare trait: " + selectedBonusTrait.name);
                }
            }

            // Assign mutation, if applicable
            let mutatedTrait = null;

            // For some reason offspringWithMutations contains objects instead of ints, so using includes() doesn't work. I hate dynamic typing.
            if (offspringWithMutations.some(num => parseInt(num) === index)) {
                mutatedTrait = getRandomFromArray(possibleMutations);

                if (mutatedTrait.category !== undefined) {
                    let replacedTraits = offspringTraits.filter(trait => trait.category === mutatedTrait.category);

                    if (replacedTraits.length > 0) {
                        let replacedTrait = replacedTraits[0];
                        console.log(offspringLogTag + "[Mutation]: Child mutated trait " + mutatedTrait.name + ", which has category " + mutatedTrait.category + " and replaces " + replacedTrait.name);
                        offspringTraits = offspringTraits.filter(trait => !traitsAreSame(trait, replacedTrait));
                    }
                    else {
                        console.log(offspringLogTag + "[Mutation]: Child mutated trait " + mutatedTrait.name + ", it did not already have any " + mutatedTrait.category);
                    }
                }
                else {
                    console.log(offspringLogTag + "[Mutation]: Child mutated trait " + mutatedTrait.name + " which is uncategorized.");
                }

                offspringTraits.push(mutatedTrait);
            }

            const offspringTraitNames = offspringTraits.map(trait => trait.name);

            const offspringHasColorMutation = offspringWithColorMutations.some(num => parseInt(num) === index);

            let mutationColumnValue = "None";
            
            if (offspringHasColorMutation && mutatedTrait === null) {
                mutationColumnValue = "Color";
            }
            else if (!offspringHasColorMutation && mutatedTrait !== null) {
                mutationColumnValue = mutatedTrait.name;
            }
            else if (offspringHasColorMutation && mutatedTrait !== null) {
                mutationColumnValue = "Color, " + mutatedTrait.name;
            }

            offspring.push({
                number: index + 1,
                species: species,
                mutation: mutationColumnValue,
                traits: offspringTraitNames
            });
        }
        setOffspring(offspring);
    };

    const getOddsForUncategorizedRarity = (rarity) => {
        if (rarity === commonRarity) {
            return props.odds.chanceToPassOnCommonTrait;
        }
        else if (rarity === uncommonRarity) {
            return props.odds.chanceToPassOnUncommonTrait;
        }
        else if (rarity === rareRarity) {
            return props.odds.chanceToPassOnRareTrait;
        }
        else if (rarity === veryRareRarity) {
            return props.odds.chanceToPassOnVRTrait;
        }
        else {
            throw new Error("Trait rarity not recognized: " + rarity);
        }
    }

    const getNumSlotsForRarity = (rarity) => {
        if (rarity === commonRarity) {
            return Math.floor(props.odds.chanceToPassOnCommonTrait * 100);
        }
        else if (rarity === uncommonRarity) {
            return Math.floor(props.odds.chanceToPassOnUncommonTrait * 100);
        }
        else if (rarity === rareRarity) {
            return Math.floor(props.odds.chanceToPassOnRareTrait * 100);
        }
        else if (rarity === veryRareRarity) {
            return Math.floor(props.odds.chanceToPassOnVRTrait * 100);
        }
        else {
            throw new Error("Trait rarity not recognized: " + rarity);
        }
    }

    const renderParentInfoPanels = () => {

        return parentTraits.map((trait, index) => 
                <BreedingParent 
                    key={index}
                    parentNumber={index}
                    parentName={"Parent " + (index + 1) }
                    breedingType="Companion"
                    allTraits={props.allCompanionTraits}
                    listedTraits={props.listedCompanionTraits}
                    possibleParents={props.companions}
                    parentTraits={parentTraits[index]}
                    onParentTraitsChanged={setTraitsOfParent}
                    onParentSpeciesChanged={setSpeciesOfParent}
                />
            );
        }

    return(
    <div>
        <h1 className="Pad-top-and-bottom-10px">Breed Companions</h1>
        <div style={{
            width: "100%",
            display: "flex",
            flexFlow: "column",
            justifyContent: "flex-start",
            alignItems: "stretch",
            gap: "15px"
        }}>
            <Form>
                <Row style={{justifyContent: "center"}}>
                    <Col style={{justifyContent: "center"}}>
                        <InputGroup className="py-2" style={{justifyContent: "center"}}>
                            <InputGroup.Text id="numberOfParentsLabel">
                            Number of parents
                            </InputGroup.Text>
                            <Form.Control
                                style={{maxWidth: "100px"}}
                                variant="dark"                   
                                type="number" 
                                min="2"
                                step="1"
                                name="numParents"
                                value={breedingSettings.numberOfParents}
                                onChange={onNumParentsChanged}/>
                        </InputGroup>
                        <InputGroup className="py-2" style={{justifyContent: "center"}}>
                            <InputGroup.Text id="numberOfChildrenLabel">
                            Number of children
                            </InputGroup.Text>
                            <Form.Control
                                style={{maxWidth: "100px"}}
                                variant="dark"                   
                                type="number"
                                name="numberOfChildren"
                                id="numberOfChildren"
                                min="0"
                                step="1"
                                value={breedingSettings.numberOfChildren}
                                onChange={onNumChildrenChanged}/>
                        </InputGroup>
                        <InputGroup className="py-2" style={{justifyContent: "center"}}>
                            <InputGroup.Text id="guaranteedVeryRareTraitsLabel">
                            Guaranteed Very Rare traits
                            </InputGroup.Text>
                            <Form.Control
                                style={{maxWidth: "100px"}}
                                variant="dark"                   
                                type="number"
                                name="guaranteedVeryRareTraits"
                                id="guaranteedVeryRareTraits"
                                min="0"
                                step="1"
                                value={breedingSettings.numGuaranteedVeryRareTraits}
                                onChange={onNumGuaranteedVeryRareTraitsChanged}/>
                        </InputGroup>
                        <InputGroup className="py-2" style={{justifyContent: "center"}}>
                            <InputGroup.Text id="guaranteeMutationLabel">
                            Guaranteed mutations
                            </InputGroup.Text>
                            <Form.Control
                                style={{maxWidth: "100px"}}
                                variant="dark"                   
                                type="number"
                                name="numGuaranteedMutations"
                                id="numGuaranteedMutations"
                                min="0"
                                step="1"
                                value={breedingSettings.numGuaranteedMutations}
                                onChange={onNumGuaranteedMutationsChanged}/>
                        </InputGroup>
                        <InputGroup className="py-2" style={{justifyContent: "center"}}>
                            <InputGroup.Text id="guaranteeColorMutationLabel">
                            Guaranteed color mutations
                            </InputGroup.Text>
                            <Form.Control
                                style={{maxWidth: "100px"}}
                                variant="dark"                   
                                type="number"
                                name="numGuaranteedColorMutations"
                                id="numGuaranteedColorMutations"
                                min="0"
                                step="1"
                                value={breedingSettings.numGuaranteedColorMutations}
                                onChange={onNumGuaranteedColorMutationsChanged}/>
                        </InputGroup>
                    </Col>
                </Row>
            </Form>
            <div style={{
                width: "100%", 
                display: "flex", 
                flexFlow: "row wrap", 
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                gap: "10px"
            }}>
                {renderParentInfoPanels()}
            </div>
            <div>
                <Button 
                    variant="success"
                    size="lg"
                    style={{width: "20%", minWidth:"100px"}}
                    onClick={breedCompanions}>
                    Breed
                </Button>
            </div>
            <h2 className="Pad-top-and-bottom-10px">Children</h2>
            <div className="ag-theme-alpine-dark" style={{padding: "1%", height: 400}}>
                <AgGridReact
                    rowData={offspring}
                    columnDefs={offspringTableColumns}
                    defaultColDef={defaultColDef}
                    animateRows={true}
                    rowSelection="multiple"
                />
            </div>
        </div>
    </div>)
}